import { renderMainApplication } from 'utils/pack_utils'
import { createLoadable } from 'components/loadable'
import 'v2/components/Multiverse/Public/index'
import JSONAPIClient from 'apiV2/apiClient'
import Cookies from 'js-cookie'
import { initializeSentry } from 'utils/sentry'

import 'i18n'

initializeSentry('public_multiverse')

const PageInfoStub = createLoadable(() => import('components/PageInfoStub'))
const PublicRouter = createLoadable(() => import('v2/Router/PublicRouter'))

const COMPONENT_LIST = {
  PageInfoStub: PageInfoStub,
  PublicRouter: PublicRouter,
}

const cookieToAuthTokenMiddleware = () => next => action => {
  const doorKeeperToken = Cookies.get('maxwell_api_v2_token')
  JSONAPIClient.headers.Authorization = `Bearer ${doorKeeperToken}`
  next(action)
}

renderMainApplication(COMPONENT_LIST, [cookieToAuthTokenMiddleware])
